import { findParentIf } from "../../../utils/cards_functions";

const initialState = {
   cards: [],
   selectedCards: [],
   statusMessage: "",
   savedGlobalData: [],
   existingGroups: [],
   conditionActive: [],
   recentActions: [],
   undoedActions: [],
   recentCards: [],

   localeDataCol: [],

   linkedLocalDataId: null,
   currentTestcaseName: null,
   sessionIdForNewTC: null,

   // States for keeping track of selected card options
   isGroupingPossible: false,
   isLoopingPossible: false,
   isDeletingPossible: false,

   groupingSelectedCards: false,
   loopingSelectedCards: false,
   ifSelectedCards: false,

   exitingIfelse: null,
   screenshotStep: null,
   isExpandedStep: null,
   deletedStepId: null,
   customInstruction: null,
   isActionDisabled: false,

   labelOrdinalForCard: null,
   allTextsForLabelOrdinal: [],
   cloneGroup: null,

   localDataType5: []
};

const updateCard = (cards, step_id, updateData) => {
   return cards.map((card) => {
      if (card.step_id === step_id) {
         // Start with the existing card
         let updatedCard = { ...card };

         // Only update inst if it exists in updateData
         // if (updateData.inst) {
         //    updatedCard.inst = {
         //       ...card.inst,
         //       ...updateData.inst,
         //    };
         // }

         if (updateData.inst) {
            updatedCard.inst = { ...card.inst };

            // If all_texts exists in original card.inst but not in updateData.inst, delete it
            if ('all_texts' in card.inst && !('all_texts' in updateData.inst)) {
               delete updatedCard.inst.all_texts;
            }

            // Update other inst fields
            Object.keys(updateData.inst).forEach(key => {
               if (key !== 'all_texts') {
                  updatedCard.inst[key] = updateData.inst[key];
               }
            });
         }

         // Only update data if it exists in updateData
         if (updateData.data !== undefined) {
            updatedCard.data = updateData.data;
         }

         // Update other fields if they exist in updateData
         const fieldsToUpdate = [
            "status",
            "ordinal",
            "message",
            "do_ordinal_selection",
            "screenshot_before",
            "screenshot_after",
            "screenshot_main",
            "screenshot_thumbnail",
            "group_id",
            "group_hash",
         ];

         fieldsToUpdate.forEach((field) => {
            if (updateData[field] !== undefined) {
               updatedCard[field] = updateData[field];
            }
         });
         return updatedCard;
      }

      // Recursively update sub_steps if present
      if (card.sub_steps) {
         return {
            ...card,
            sub_steps: updateCard(card.sub_steps, step_id, updateData),
         };
      }

      return card;
   });
};

const handleDeleteCard = (cards, deletedCardStepId) => {
   // Delete the card and update step_ids
   const deleteCard = (cards, deletedCardStepId) => {
      const deleteAndUpdateIds = (cardsArray, parentId = "") => {
         let nextIndex = 1;
         return cardsArray.reduce((acc, card) => {
            if (card.step_id === deletedCardStepId) {
               return acc; // Skip this card as it's being deleted
            }
            const newParts = parentId
               ? [...parentId.split("."), nextIndex.toString()]
               : [nextIndex.toString()];
            const newStepId = newParts.join(".");

            nextIndex++;

            const updatedCard = { ...card, step_id: newStepId };
            if (updatedCard.sub_steps && updatedCard.sub_steps.length > 0) {
               updatedCard.sub_steps = deleteAndUpdateIds(
                  updatedCard.sub_steps,
                  newStepId
               );
            }
            return [...acc, updatedCard];
         }, []);
      };
      return deleteAndUpdateIds(cards);
   };
   const semiUpdatedCards = deleteCard(cards, deletedCardStepId);

   // Function to update parent_if for all cards
   const updateParentIf = (cards) => {
      return cards.map((card) => {
         let updatedCard = { ...card };
         if (updatedCard.inst && updatedCard.inst.parent_if) {
            const newParentIf = findParentIf(
               semiUpdatedCards,
               updatedCard.step_id
            );

            updatedCard.inst = {
               ...updatedCard.inst,
               parent_if: newParentIf,
            };
         }
         if (updatedCard.sub_steps && updatedCard.sub_steps.length > 0) {
            updatedCard.sub_steps = updateParentIf(updatedCard.sub_steps);
         }

         return updatedCard;
      });
   };

   // Update parent_if for all cards
   const finalUpdatedCards = updateParentIf(semiUpdatedCards);

   return { updatedCards: finalUpdatedCards, deletedStepId: deletedCardStepId };
};

// Helper function to ensure uniqueness
const addUniqueCardId = (array, cardId) => {
   return array.includes(cardId) ? array : [...array, cardId];
};

const cardsReducer = (state = initialState, action) => {
   switch (action.type) {
      case "RESET_CARDS":
         return {
            ...state,
            cards: [],
            recentActions: [],
            undoneActions: [],
         };

      case "SET_SESSION_ID_FOR_NEW_TC":
         return {
            ...state,
            sessionIdForNewTC: action.payload,
         };

      case "ADD_CARD":
         const actionWithHardCode = {
            ...action.payload,
            message:
               action.payload.paste === 5
                  ? "Not Yet Started"
                  : action.payload.message,
            status: action.payload.paste === 5 ? 5 : action.payload.status,
         };
         const {
            inst = {
               instr_type: null,
               instr: "",
               label_type: null,
               label: "",
               contextual_label_type: null,
               contextual_label: "",
            },
            step_id,
            data,
            status,
            ordinal,
            do_ordinal_selection,
            sub_steps = [],
            message,
            screenshot_before,
            screenshot_after,
            screenshot_main,
            screenshot_thumbnail,
         } = actionWithHardCode;

         const cardDetails = {
            inst,
            step_id,
            data,
            status,
            ordinal,
            do_ordinal_selection,
            sub_steps,
            parent_id: null,
            message,
            screenshot_before,
            screenshot_after,
            screenshot_main,
            screenshot_thumbnail,
         };
         if (action.payload.group_id) {
            // console.log("group_id", action.payload.group_id);
            cardDetails.group_id = action.payload.group_id;
         }
         if (action.payload.group_hash) {
            cardDetails.group_hash = action.payload.group_hash;
         }

         if (action.payload.condition_instr) {
            cardDetails.condition_instr = action.payload.condition_instr;
         }
         if (action.payload.instr_type) {
            cardDetails.instr_type = action.payload.instr_type;
         }

         const addCardToHierarchy = (cards, newCard) => {
            const stepIdParts = newCard.step_id.split(".");
            const parentStepId = stepIdParts.slice(0, -1).join(".");
            const currentStepId = stepIdParts.join(".");

            if (parentStepId === "") {
               // This is a top-level card
               return insertCard(cards, newCard);
            }

            return cards.map((card) => {
               if (card.step_id === parentStepId) {
                  const updatedCard = {
                     ...card,
                     sub_steps: insertCard(
                        card.sub_steps ? [...card.sub_steps] : [],
                        newCard
                     ),
                  };
                  newCard.parent_id = parentStepId;
                  return updatedCard;
               }

               if (card.sub_steps && card.sub_steps.length > 0) {
                  return {
                     ...card,
                     sub_steps: addCardToHierarchy(card.sub_steps, newCard),
                  };
               }

               return card;
            });
         };

         const insertCard = (cards, newCard) => {
            const existingIndex = cards.findIndex(
               (card) => card.step_id === newCard.step_id
            );
            if (existingIndex !== -1) {
               const newSubSteps = [
                  ...cards.slice(0, existingIndex),
                  newCard,
                  ...cards.slice(existingIndex),
               ];
               return adjustStepIds(newSubSteps, existingIndex + 1);
            } else {
               return [...cards, newCard];
            }
         };

         const adjustStepIds = (cards, startIndex) => {
            return cards.map((card, index) => {
               if (index >= startIndex) {
                  const stepIdParts = card.step_id.split(".");
                  stepIdParts[stepIdParts.length - 1] = (
                     parseInt(stepIdParts[stepIdParts.length - 1], 10) + 1
                  ).toString();
                  const newStepId = stepIdParts.join(".");

                  return {
                     ...card,
                     step_id: newStepId,
                     sub_steps: adjustSubStepIds(
                        card.sub_steps ? [...card.sub_steps] : [],
                        newStepId
                     ),
                  };
               }
               return card;
            });
         };

         const adjustSubStepIds = (subSteps, parentStepId) => {
            return subSteps.map((subStep, index) => {
               const newSubStepId = `${parentStepId}.${index + 1}`;

               return {
                  ...subStep,
                  step_id: newSubStepId,
                  sub_steps: adjustSubStepIds(
                     subStep.sub_steps ? [...subStep.sub_steps] : [],
                     newSubStepId
                  ),
               };
            });
         };

         const newCards = addCardToHierarchy([...state.cards], cardDetails);

         // Function to update parent_if for all cards
         const updateParentIf = (cards) => {
            return cards.map((card) => {
               let updatedCard = { ...card };
               if (updatedCard.inst && updatedCard.inst.parent_if) {
                  const newParentIf = findParentIf(
                     newCards,
                     updatedCard.step_id
                  );
                  updatedCard.inst = {
                     ...updatedCard.inst,
                     parent_if: newParentIf,
                  };
               }
               if (updatedCard.sub_steps && updatedCard.sub_steps.length > 0) {
                  updatedCard.sub_steps = updateParentIf(updatedCard.sub_steps);
               }
               return updatedCard;
            });
         };

         // Update parent_if for all cards
         const finalUpdatedCards = updateParentIf(newCards);

         return {
            ...state,
            cards: finalUpdatedCards,
            // recentCards: addUniqueCardId(state.recentCards, action.payload.step_id),
         };

      case "DELETE_CARD":

         // Call handleDeleteCard and return its result directly
         const result = handleDeleteCard(state.cards, action.payload.id);

         return {
            ...state,
            cards: result.updatedCards, // Updated cards from handleDeleteCard
            recentCards: state.recentCards.filter(
               (id) => id !== action.payload.id
            ),
            deletedStepId: result.deletedStepId, // Store the deleted step_id from result
         };

      case "DELETE_CARD_SUBSTEPS":
         const deleteSubSteps = (cards, step_id) => {
            return cards.map((card) => {
               if (card.step_id === step_id) {
                  return {
                     ...card,
                     sub_steps: [],
                  };
               }
               if (card.sub_steps && card.sub_steps.length > 0) {
                  return {
                     ...card,
                     sub_steps: deleteSubSteps(card.sub_steps, step_id),
                  };
               }
               return card;
            });
         };

         const updatedCardsAfterDeleteSubSteps = deleteSubSteps(
            state.cards,
            action.payload.step_id
         );

         return {
            ...state,
            cards: updatedCardsAfterDeleteSubSteps,
         };

      case "EDIT_CARD":
         const updatedCards = updateCard(
            state.cards,
            action.payload.step_id,
            action.payload
         );

         return {
            ...state,
            cards: updatedCards,
            recentCards: addUniqueCardId(
               state.recentCards,
               action.payload.step_id
            ),
         };

      case "SET_STATUS_MESSAGE":
         return {
            ...state,
            statusMessage: action.payload,
         };

      case "SET_CONDITION_ACTIVE":
         // console.log("action.payload", action.payload);
         return {
            ...state,
            conditionActive: [...state.conditionActive, action.payload],
         };

      case "SET_ALL_CONDITION_ACTIVE":
         // console.log("action.payload", action.payload);
         return {
            ...state,
            conditionActive: [...action.payload],
         };

      case "POP_CONDITION_ACTIVE":
         return {
            ...state,
            conditionActive: state.conditionActive.slice(0, -1),
         };

      case "POP_ALL_CONDITION_ACTIVE":
         return {
            ...state,
            conditionActive: [],
         };

      case "SET_LINKED_LOCAL_DATA_ID":
         return {
            ...state,
            linkedLocalDataId: action.payload,
         };

      case "SET_CURRENT_TESTCASE_NAME":
         return {
            ...state,
            currentTestcaseName: action.payload,
         };

      case "SET_EXISTING_GROUPS":
         return {
            ...state,
            existingGroups: action.payload,
         };

      case "ADD_TO_SELECTED_CARD":
         return {
            ...state,
            selectedCards: [...state.selectedCards, action.payload],
         };

      case "REMOVE_SELECTED_CARD":
         return {
            ...state,
            selectedCards: state.selectedCards.filter(
               (step_id) => step_id !== action.payload
            ),
         };

      case "REMOVE_ALL_SELECTED_CARDS":
         return {
            ...state,
            selectedCards: [],
         };

      case "IS_GROUP_POSSIBLE_FOR_SELECTED_CARDS":
         return {
            ...state,
            isGroupingPossible: action.payload,
         };

      case "IS_LOOP_POSSIBLE_FOR_SELECTED_CARDS":
         return {
            ...state,
            isLoopingPossible: action.payload,
         };

      case "IS_DELETE_POSSIBLE_FOR_SELECTED_CARDS":
         return {
            ...state,
            isDeletingPossible: action.payload,
         };

      case "GROUPING_SELECTED_CARDS":
         return {
            ...state,
            groupingSelectedCards: action.payload,
         };

      case "LOOPING_SELECTED_CARDS":
         return {
            ...state,
            loopingSelectedCards: action.payload,
         };

      case "SET_IF_SELECTED_CARDS":
         return {
            ...state,
            ifSelectedCards: action.payload,
         };

      case "SET_EXITING_IFELSE":
         return {
            ...state,
            exitingIfelse: action.payload,
         };

      case "ADD_RECENT_ACTION":
         return {
            ...state,
            recentActions: [...state.recentActions, action.payload],
         };
      // case "ADD_RECENT_ACTION":
      //    const updatedActions = [...state.recentActions, action.payload];
      //    return {
      //      ...state,
      //      recentActions: updatedActions.slice(-2)
      //    };

      case "ADD_UNDOED_ACTION":
         return {
            ...state,
            undoedActions: [...state.undoedActions, action.payload],
         };

      case "POP_RECENT_ACTION":
         return {
            ...state,
            recentActions: state.recentActions.slice(0, -1),
         };

      case "POP_NUMBER_OF_RECENT_ACTION":
         const numberToPop = action.payload > 0 ? action.payload : 1; // Default to 1 if no payload
         return {
            ...state,
            recentActions: state.recentActions.slice(0, -numberToPop),
         };

      case "POP_UNDOED_ACTION":
         return {
            ...state,
            undoedActions: state.undoedActions.slice(0, -1),
         };

      case "POP_ALL_UNDOED_ACTIONS":
         return {
            ...state,
            undoedActions: [],
         };

      case "POP_ALL_RECENT_ACTIONS":
         return {
            ...state,
            recentActions: [],
         };

      case "SCREENSHOT_STEP":
         return {
            ...state,
            screenshotStep: action.payload,
         };

      case "IS_EXPANDED_STEP":
         return {
            ...state,
            isExpandedStep: action.payload,
         };
      case "UPDATE_RECENT_STEP":
         return {
            ...state,
            recentCards: addUniqueCardId(state.recentCards, action.payload),
         };

      case "CLEAR_DELETED_STEP_ID":
         return {
            ...state,
            deletedStepId: null,
         };
      case "UPDATE_CUSTOM_INSTRUCTION":
         return {
            ...state,
            customInstruction: action.payload,
         };

      case "SET_ACTION_DISABILITY":
         return {
            ...state,
            isActionDisabled: action.payload,
         };

      case "CLONE_GROUP_DETAILS":
         return {
            ...state,
            cloneGroup: action.payload,
         };

      case "SHOW_LABEL_ORDINAL_FOR_CARD":
         return {
            ...state,
            labelOrdinalForCard: action.payload,
         }

      case "SET_ALL_TEXTS_FOR_LABEL_ORDINAL":
         return {
            ...state,
            allTextsForLabelOrdinal: action.payload,
         }
      
      case "SET_LOCAL_DATA_TYPE_5":
         return{
            ...state,
            localDataType5: action.payload
         }

      default:
         return state;
   }
};

export default cardsReducer;
