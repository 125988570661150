import React, { useState, useRef, useEffect } from "react";
import "./customInstructionConstructor.css";
import EditableSpan from "./ceEditableSpan";
import cross from "../../assets/failStatus.svg";
import "./Dropdown.css";

export const processWords = (words) => {
   return words.flatMap((wordObj) => {
      if (wordObj.word.includes(" ")) {
         return wordObj.word.split(" ").map((word) => ({ word: word.trim() }));
      }
      return wordObj;
   });
};

const CustomInstructionConstructor = ({ words, setWords, dropDownValues, disable = false }) => {
   const [toggle, setToggle] = useState(false);
   const [currentInput, setCurrentInput] = useState("");
   const [visibleDropdownIndex, setVisibleDropdownIndex] = useState(null);
   const inputRef = useRef(null);
   const hasHighlightRef = useRef(false);
   const [usedValues, setUsedValues] = useState([]);

   // Handle input change
   const handleInputChange = (e) => {
      setCurrentInput(e.target.value);
      if (hasHighlightRef.current) {
         resetHighlight();
         hasHighlightRef.current = false;
      }
      setVisibleDropdownIndex(null);
   };

   // Add highlight to the last word on backspace
   const handleKeyDown = (e) => {
      if (e.key === " " || e.key === "Enter") {
         addWord();
         e.preventDefault();
      } else if (e.key === "Backspace" && currentInput === "") {
         handleBackspace();
      }
   };

   const handleBackspace = () => {
      setWords((prevWords) => {
         const updatedWords = [...prevWords];
         const lastWord = updatedWords[updatedWords.length - 1];
         if (lastWord && lastWord.highlight) {
            updatedWords.pop();
            resetHighlight();
         } else if (lastWord) {
            lastWord.highlight = true;
            hasHighlightRef.current = true;
         }
         return updatedWords;
      });
   };

   // Handle word addition
   const addWord = (fromBlur = false) => {
      const trimmedWord = currentInput.trim();
      if (trimmedWord !== "") {
         // Add word to the array
         setWords((prevWords) => [...prevWords, { word: trimmedWord }]);
         // Clear input
         setCurrentInput("");
         // Focus input again
         if (!fromBlur) {
            inputRef.current.focus();
         }
      }
   }; // <-- Ensure this closing brace is present

   // Reset highlight on input blur
   const handleBlur = () => {
      let fromBlur = true;
      addWord(fromBlur);
      resetHighlight();
   };

   // Function to reset highlight
   const resetHighlight = () => {
      setWords((prevWords) =>
         prevWords.map((word) => {
            if (word.highlight) {
               return { ...word, highlight: false };
            }
            return word;
         })
      );
   };

   const handleRefreshWords = () => {
      setToggle(!toggle);
   };

   useEffect(() => {
      setWords(processWords(words));
   }, [toggle]);

   const handleSelect = (value, type) => {
      const updatedWords = [...words];
      const index = visibleDropdownIndex;

      // Remove the value from where it was previously used
      const previousIndex = updatedWords.findIndex(
         (word) => word.arg_name === value
      );
      if (previousIndex !== -1) {
         updatedWords[previousIndex].arg_name = null;
         updatedWords[previousIndex].arg_type = null;
      }

      // Assign the value to the current word
      updatedWords[index].arg_name = value;
      updatedWords[index].arg_type = type;

      setWords(updatedWords);
      setVisibleDropdownIndex(null);
      updateUsedValues(updatedWords);
   };

   const updateUsedValues = (words) => {
      const used = words
         .filter((word) => word.arg_name)
         .map((word) => word.arg_name);
      setUsedValues(used);
   };

   useEffect(() => {
      updateUsedValues(words);
   }, [words]);

   const handleShowDropdown = (index) => {
      setVisibleDropdownIndex(index);
   };

   const handleHideDropdown = () => {
      setVisibleDropdownIndex(null);
   };

   return (
      <div className="word-constructor-container">
         <div className="word-constructor">
            <img
               src={cross}
               width={18}
               height={18}
               alt="close"
               className="ce-close-icon"
               onClick={() => setWords([])}
            />
            <div className="main-constructor-container">
               <EditableSpan
                  words={words}
                  setWords={setWords}
                  handleRefreshWords={handleRefreshWords}
                  handleShowDropdown={handleShowDropdown}
                  handleHideDropdown={handleHideDropdown}
                  visibleDropdownIndex={visibleDropdownIndex}
                  dropDownValues={dropDownValues}
                  usedValues={usedValues}
                  handleSelect={handleSelect}
               />
               <input
                  ref={inputRef}
                  className="new-word"
                  value={currentInput}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  onBlur={handleBlur}
                  spellCheck={false}
                  placeholder="Type and press space"
                  onFocus={handleHideDropdown}
               />
            </div>
         </div>
         <p
            style={{
               fontSize: "12px",
               color: "#cec7c7",
               margin: 0,
               marginTop: "5px",
            }}
         >
            *double click on word to make it a variable
         </p>
      </div>
   );
};

export default CustomInstructionConstructor;
